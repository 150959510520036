<template>
<div class="document-list">
    <div class="label">
        {{label}}
    </div>
    <div class="field">
        <a
            v-for="doc in list"
            :key="doc.id"
            class="document"
            :href="doc.url"
            download
        >
            {{ doc.name }}
        </a>
        <slot name="mark"></slot>
    </div>
    <button
        class="upload"
        @click="$emit('upload')"
    >Upload Document</button>
</div>
</template>

<script>
export default {
    name: 'comparison-document-list',
    props: {
        label: {
            type: String,
            required: true,
        },
        list: Array,
    },
}
</script>

<style lang="scss" scoped>
.document-list {
    .label {
        line-height: 21px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 7px;
    }
    .field {
        height: 42px;
        background-color: #fff;
        padding: 7px 7px 7px 16px;
        box-sizing: border-box;
        border-radius: 12px;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 16px;
        .document {
            font-weight: normal;
            font-size: 14px;
            text-decoration: none;
            color: #0D69D5;
        }
    }
    .upload {
        display: flex;
        width: 100%;
        height: 42px;
        border-radius: 12px;
        align-items: center;
        justify-content: center;
        font-weight: normal;
        background-color: #fff;
        border: 1px dashed #0D69D5;
        cursor: pointer;
    }
}
</style>