<template>
    <div class="content">
        <!-- <div>
            Selected: {{ selected }}
        </div> -->
        <Card
            @click.native="selectCard(card.id)"
            v-for="card in testList"
            :key="card.id"
            :data="card"
            :statusList="statusList"
            :selected="selected"
        />
        <hr>
        <Comparison :data="comparisonData" />
    </div>
</template>

<script>
import Card from "@/components/sc-journey/card";
import Comparison from "@/components/sc-journey/comparison";

export default {
    name: "sc-journey",
    components: {
        Card,
        Comparison,
    },
    data() {
        return {
            selected: '',
            testList: [],
            statusList: [],
            comparisonData: {}
        }
    },
    methods: {
        selectCard(id) {
            this.selected = this.selected === id ? '' : id;
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	background-color: #EDF2F7;
}
</style>