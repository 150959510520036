<template>
<div class="comparison-text">
    <div class="label">
        {{label}}
    </div>
    <div class="field-wrapper">
        <input
            type="text"
            class="field"
            :value="initialValue"
            :disabled="disabled"
        >
        <slot name="mark"></slot>
    </div>
</div>
</template>

<script>
export default {
    name: 'comparison-text',
    props: {
        label: {
            type: String,
            required: true,
        },
        initialValue: String,
        disabled: Boolean,
    },
    data() {
        return {
            value: null,
        }
    },
    created() {
        this.value = this.initialValue;
    },
}
</script>

<style lang="scss" scoped>
.comparison-text {
    .label {
        line-height: 21px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 7px;
    }
    .field-wrapper {
        position: relative;
        .field {
            height: 42px;
            background-color: #fff;
            padding: 7px 7px 7px 16px;
            box-sizing: border-box;
            border-radius: 12px;
            border: none;
            &:focus{
                border: 1px solid #0D69D5 ;
            }
        }
        .mark {
            bottom: 4px;
        }
    }
}
</style>