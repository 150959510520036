<template>
<div class="comparison-select">
    <div class="label">
        {{label}}
    </div>
    <div class="selector">
        <VueMultiselect
            :options="options"
            :multiple="false"
            :searchable="false"
            :allowEmpty="false"
            selectLabel=""
            deselectLabel=""
            placeholder=""
            v-model="value"
            @input="$emit('input', $event)"
            :disabled="isDisabled"
        >
            <template #caret="{toggle}">
                <div
                    class="caret"
                    :class="isDisabled ? 'tw-hidden' : ''"
                    @mousedown.prevent.stop="toggle()"
                >
                    <dropdownIcon
                        fill="#282828"
                    />
                </div>
            </template>
        </VueMultiselect>
    </div>
    <slot name="mark"></slot>
</div>
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";
import dropdownIcon from "@/assets/icons/sc-journey/dropdown.svg";

export default {
    name: 'comparison-select',
    components: {
        VueMultiselect,
        dropdownIcon,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        initialValue: String,
        options: {
            type: Array,
            default: () => [],
        },
        disabled: Boolean,
    },
    data() {
        return {
            value: null,
        }
    },
    created() {
        this.value = this.options.find(el => el.value === this.initialValue) || this.initialValue;
    },
    computed: {
        isDisabled() {
            return !this.options.length;
        },
    },
}
</script>

<style lang="scss" scoped>
.comparison-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    background-color: #fff;
    padding: 7px 7px 7px 16px;
    box-sizing: border-box;
    border-radius: 12px;
    .label {
        font-size: 14px;
        white-space: nowrap;
    }
    .selector {
        background-color: #DFDFDF;
        width: 120px;
        align-self: stretch;
        position: relative;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        .caret {
            display: inline-flex;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 10px;
            padding: 5px 1px;
            z-index: 1;
            &.tw-hidden {
                display: none;
            }
            svg {
                width: 12px;
                transition: transform .2s;
            }
        }
        ::v-deep {
            .multiselect {
                outline: none;
                width: 100%;
                &--active {
                    .caret {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
            .multiselect__content-wrapper {
                width: auto;
            }
            .multiselect__tags {
                background: none;
                border: none;
                min-height: 24px;
                width: 100%;
                box-sizing: border-box;
                padding: 0 23px 0 10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            .multiselect__single {
                background: none;
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .multiselect__select {
                z-index: 1;
            }
            .multiselect__option {
                font-size: 14px;
                min-height: 18px;
                box-sizing: border-box;
                padding: 10px;
                color: #282828;
            }
            .multiselect__placeholder {
                margin: 0;
            }
        }
    }
}
</style>